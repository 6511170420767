import {
  ArrowDownAZ,
  ArrowLeftCircle,
  ArrowUpAZ,
  ArrowUpNarrowWide,
  AtSign,
  Bookmark,
  BookmarkMinus,
  BookmarkPlus,
  CalendarArrowDown,
  CalendarArrowUp,
  Camera,
  Check,
  ChevronDown,
  Copy,
  Ellipsis,
  ExternalLink,
  Eye,
  Globe,
  Hash,
  Heart,
  House,
  Image,
  Link,
  ListPlus,
  Loader,
  Lock,
  LogIn,
  LogOut,
  MapPin,
  MessageCircleReply,
  MessageCircleX,
  PenLine,
  Pin,
  PinOff,
  PlusCircle,
  RefreshCcw,
  Rss,
  Save,
  Search,
  Send,
  Settings2,
  Tag,
  Tags,
  Ticket,
  Trash,
  User,
  UserCheck,
  Users,
  UserX,
  X,
  XCircle,
} from 'lucide-react';
import { Bluesky, Farcaster, Lens, Logo, LogoGray, Mastodon, Spacehost, SpacehostText } from '~/assets/icons';

// Define a map of icon components
const icons = {
  // Logo
  logo: Logo,
  logoGray: LogoGray,
  spacehost: Spacehost,
  spacehostText: SpacehostText,

  // Network Icons
  bluesky: Bluesky,
  farcaster: Farcaster,
  lens: Lens,
  mastodon: Mastodon,

  // Login Icons
  extLink: ExternalLink,
  login: LogIn,
  logout: LogOut,
  atSign: AtSign,
  server: Globe,
  lock: Lock,
  ticket: Ticket,

  // Bookmark Icons
  bookmark: Bookmark,
  bookmarkMinus: BookmarkMinus,
  bookmarkPlus: BookmarkPlus,

  // Pin Icons
  pin: Pin,
  unpin: PinOff,

  // Feed Type Icons
  hashtag: Hash,
  location: MapPin,
  users: Users,
  advanced: Settings2,

  // Avatar Icons
  image: Image,
  loader: Loader,
  camera: Camera,

  // Buttons Icons
  listPlus: ListPlus,
  eye: Eye,
  close: XCircle,
  back: ArrowLeftCircle,
  save: Save,
  home: House,
  retry: RefreshCcw,

  // Filter Icons
  more: Ellipsis,
  removeAuthor: UserX,
  removePost: MessageCircleX,
  restoreAuthor: UserCheck,
  restorePost: MessageCircleReply,

  // Actions Icons
  sort: ArrowUpNarrowWide,
  search: Search,
  heart: Heart,
  trash: Trash,
  edit: PenLine,
  rss: Rss,
  send: Send,
  link: Link,
  add: PlusCircle,
  plus: PlusCircle,

  // Form Icons
  tag: Tag,
  tags: Tags,
  check: Check,
  x: X,

  // Profile Icons
  user: User,

  // Sort Icons
  newest: CalendarArrowDown,
  oldest: CalendarArrowUp,
  aToZ: ArrowDownAZ,
  zToA: ArrowUpAZ,

  settings: Settings2,

  copy: Copy,

  chevronDown: ChevronDown,
} as const;

// Define IconName as valid icon names
export type IconName = keyof typeof icons;

export const renderIcon = (iconName: IconName, size: 4 | 5 | 6 | 10 | 12 | 20 | 28 = 6) => {
  const IconComponent = icons[iconName];
  return <IconComponent className={`w-${size} h-${size}`} />;
};
